import Header from "../header";
import { ToastContainer } from "react-toastify";
export default function AdminLayout({ children }) {
  return (
    <div>
      <Header />
      <main>{children}</main>
      <footer></footer>
      <ToastContainer position="bottom-right" />
    </div>
  );
}
