export default function Logo({ classes }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_2"
      dataName="Layer 2"
      viewBox="0 0 78.14 11.27"
      height="33"
      width="110"
      className={classes}
    >
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html: `.cls-1 {
              fill: url(#linear-gradient);
            }`,
          }}
        />

        <linearGradient
          id="linear-gradient"
          x1="63.67"
          y1="5.56"
          x2="78.14"
          y2="5.56"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#ff4000" />
          <stop offset="1" stopColor="#ff5c00" />
        </linearGradient>
      </defs>
      <g id="Layer_1-2" dataName="Layer 1">
        <g>
          <path
            className="cls-1"
            d="M78.14,4.22L74.1,.19l-4.03,4.03,1.08,1.08,2.2-2.2v5.64c0,.47-.38,.86-.86,.86h-6.45c-.47,0-.86-.38-.86-.86V2.38c0-.47,.38-.86,.86-.86h4.49V0S66.04,0,66.04,0c-1.31,0-2.38,1.07-2.38,2.38v6.36c0,1.31,1.07,2.38,2.38,2.38h6.45c.66,0,1.25-.27,1.68-.7,.43-.43,.7-1.02,.7-1.68V3.1l2.2,2.2,1.08-1.08Z"
          />
          <g>
            <path d="M7.16,4c-.07-.25-.17-.47-.3-.66-.13-.19-.29-.36-.47-.49-.19-.13-.4-.24-.64-.31-.24-.07-.5-.11-.79-.11-.54,0-1.01,.13-1.41,.4s-.72,.65-.95,1.16c-.23,.51-.34,1.13-.34,1.86s.11,1.36,.33,1.87c.22,.51,.54,.9,.94,1.17,.41,.27,.89,.4,1.44,.4,.5,0,.93-.09,1.29-.27,.36-.18,.63-.43,.82-.76,.19-.33,.28-.72,.28-1.17l.45,.07h-2.71v-1.67h4.4v1.32c0,.92-.19,1.72-.59,2.38-.39,.66-.93,1.17-1.61,1.53s-1.47,.54-2.35,.54c-.99,0-1.85-.22-2.6-.65-.75-.44-1.33-1.06-1.74-1.86s-.62-1.76-.62-2.87c0-.85,.12-1.61,.37-2.28,.25-.67,.6-1.24,1.04-1.7s.97-.82,1.57-1.06c.6-.24,1.24-.36,1.94-.36,.6,0,1.15,.09,1.66,.26,.51,.17,.97,.42,1.37,.73,.4,.31,.72,.69,.98,1.12,.25,.43,.42,.91,.49,1.43h-2.26Z" />
            <path d="M12.8,11.27c-.5,0-.95-.09-1.35-.26s-.7-.44-.93-.79c-.23-.35-.34-.78-.34-1.3,0-.44,.08-.81,.24-1.1s.38-.54,.66-.72c.28-.18,.59-.32,.95-.41s.73-.16,1.12-.19c.46-.05,.83-.09,1.11-.14,.28-.04,.48-.11,.61-.19s.19-.21,.19-.38v-.03c0-.33-.1-.58-.31-.76-.2-.18-.49-.27-.87-.27-.39,0-.71,.09-.94,.26-.23,.17-.39,.39-.46,.65l-2.02-.16c.1-.48,.3-.89,.61-1.25,.3-.35,.69-.62,1.17-.81,.48-.19,1.03-.28,1.66-.28,.44,0,.86,.05,1.26,.15s.76,.26,1.07,.48c.31,.22,.56,.49,.74,.83,.18,.34,.27,.74,.27,1.21v5.32h-2.07v-1.09h-.06c-.13,.25-.3,.46-.51,.65s-.47,.33-.77,.44c-.3,.1-.64,.16-1.03,.16Zm.63-1.51c.32,0,.61-.06,.85-.19,.25-.13,.44-.3,.58-.52,.14-.22,.21-.47,.21-.74v-.84c-.07,.04-.16,.08-.28,.12s-.25,.07-.4,.1c-.15,.03-.29,.05-.44,.08-.15,.02-.28,.04-.4,.06-.26,.04-.48,.1-.67,.18-.19,.08-.34,.19-.45,.33s-.16,.31-.16,.52c0,.3,.11,.52,.33,.68,.22,.16,.49,.23,.83,.23Z" />
            <path d="M22.45,3.23v1.64h-4.75v-1.64h4.75Zm-3.67-1.89h2.19v7.35c0,.2,.03,.36,.09,.47,.06,.11,.15,.19,.26,.23s.24,.07,.39,.07c.1,0,.21,0,.31-.03s.18-.03,.24-.04l.34,1.63c-.11,.03-.26,.07-.46,.12-.2,.05-.44,.07-.72,.08-.53,.02-.99-.05-1.38-.21-.4-.16-.7-.41-.92-.75-.22-.34-.33-.77-.32-1.28V1.34Z" />
            <path d="M26.65,11.27c-.81,0-1.51-.16-2.09-.49-.58-.33-1.03-.8-1.35-1.41-.31-.61-.47-1.33-.47-2.16s.16-1.52,.47-2.14c.31-.61,.76-1.09,1.33-1.43,.57-.34,1.25-.51,2.02-.51,.52,0,1.01,.08,1.46,.25,.45,.17,.84,.42,1.18,.75s.6,.75,.79,1.25c.19,.5,.28,1.09,.28,1.76v.6h-6.66v-1.36h4.6c0-.31-.07-.59-.21-.84-.14-.24-.33-.43-.57-.57-.24-.14-.52-.21-.84-.21s-.63,.08-.88,.23-.45,.36-.6,.61c-.14,.25-.22,.54-.22,.85v1.29c0,.39,.07,.73,.22,1.01s.35,.5,.62,.66c.27,.15,.58,.23,.95,.23,.24,0,.47-.03,.67-.1,.2-.07,.37-.17,.52-.31,.14-.14,.25-.3,.33-.5l2.02,.13c-.1,.49-.31,.91-.63,1.27-.32,.36-.72,.64-1.22,.84s-1.07,.3-1.72,.3Z" />
            <path d="M33.39,.6V11.12h-2.19V.6h2.19Z" />
            <path d="M35.72,2.21c-.33,0-.6-.11-.83-.33-.23-.22-.35-.48-.35-.78s.12-.56,.35-.78c.23-.22,.51-.33,.83-.33s.6,.11,.83,.33c.23,.22,.35,.48,.35,.78s-.12,.57-.35,.78-.51,.33-.83,.33Zm-1.1,8.9V3.23h2.19v7.89h-2.19Z" />
            <path d="M40.24,6.56v4.56h-2.19V3.23h2.08v1.39h.09c.17-.46,.47-.82,.88-1.09,.41-.27,.91-.4,1.49-.4,.55,0,1.03,.12,1.43,.36,.41,.24,.72,.58,.95,1.02,.23,.44,.34,.97,.34,1.58v5.02h-2.19V6.48c0-.48-.12-.86-.37-1.13-.25-.27-.59-.41-1.03-.41-.29,0-.55,.06-.78,.19s-.4,.31-.52,.55c-.13,.24-.19,.53-.19,.87Z" />
            <path d="M46.53,11.12V.6h2.19V11.12h-2.19Zm1.98-2.27v-2.62h.32l2.53-2.99h2.51l-3.39,3.96h-.52l-1.45,1.65Zm2.95,2.27l-2.32-3.43,1.46-1.55,3.42,4.98h-2.56Z" />
            <path d="M60.65,5.48l-2,.12c-.03-.17-.11-.33-.22-.46s-.26-.25-.44-.33-.4-.13-.65-.13c-.34,0-.62,.07-.86,.21-.23,.14-.35,.33-.35,.57,0,.19,.08,.35,.23,.48,.15,.13,.41,.23,.78,.31l1.43,.29c.77,.16,1.34,.41,1.71,.76s.56,.81,.56,1.38c0,.52-.15,.97-.45,1.36s-.72,.69-1.24,.91c-.53,.22-1.13,.33-1.82,.33-1.04,0-1.87-.22-2.49-.65s-.98-1.03-1.09-1.78l2.15-.11c.06,.32,.22,.56,.47,.73,.25,.17,.57,.25,.96,.25s.69-.07,.93-.22c.23-.15,.35-.34,.36-.58,0-.2-.09-.36-.25-.49s-.42-.23-.76-.3l-1.37-.27c-.77-.15-1.34-.42-1.72-.8-.37-.38-.56-.86-.56-1.45,0-.51,.14-.94,.41-1.31,.28-.37,.66-.65,1.17-.85,.5-.2,1.09-.3,1.76-.3,1,0,1.78,.21,2.35,.63,.57,.42,.91,.99,1,1.72Z" />
          </g>
        </g>
      </g>
    </svg>
  );
}
