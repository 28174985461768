import "../styles/globals.css";
import { Inter } from "@next/font/google";
import { SWRConfig } from "swr";
import { SessionProvider, useSession } from "next-auth/react";
import "react-toastify/dist/ReactToastify.css";
import AdminLayout from "../components/layouts/admin";
import { useRouter } from "next/router";
import * as Fathom from "fathom-client";
import { useEffect } from "react";
import Head from "next/head";

const inter = Inter({
  subsets: ["latin"],
  variable: "--font-inter",
});

function GateLinks({ Component, pageProps: { session, ...pageProps } }) {
  const router = useRouter();
  useEffect(() => {
    // Initialize Fathom when the app loads
    // Example: yourdomain.com
    //  - Do not include https://
    //  - This must be an exact match of your domain.
    //  - If you're using www. for your domain, make sure you include that here.
    Fathom.load("XEXMTYDN", {
      includedDomains: ["www.glks.io"],
    });

    function onRouteChangeComplete() {
      Fathom.trackPageview();
    }
    // Record a pageview when route changes
    router.events.on("routeChangeComplete", onRouteChangeComplete);

    // Unassign event listener
    return () => {
      router.events.off("routeChangeComplete", onRouteChangeComplete);
    };
  }, []);

  return (
    <SWRConfig
      value={{
        refreshInterval: 3000,
        fetcher: (resource, init) =>
          fetch(resource, init).then((res) => res.json()),
      }}
    >
      <Head>
        <title>Gatelinks</title>
        <meta name="description" content="Give Link, Get Email" />
        <link rel="icon" href="/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <SessionProvider session={session}>
        <div className={`${inter.className} font-san`}>
          {Component.auth ? (
            <Auth>
              <AdminLayout>
                <Component {...pageProps} />
              </AdminLayout>
            </Auth>
          ) : (
            <Component {...pageProps} />
          )}
        </div>
      </SessionProvider>
    </SWRConfig>
  );
}

export default GateLinks;

function Auth({ children }) {
  const router = useRouter();
  // if `{ required: true }` is supplied, `status` can only be "loading" or "authenticated"
  const { status } = useSession({
    required: true,
    onUnauthenticated() {
      router.push("/login");
    },
  });

  if (status === "loading") {
    return (
      <div className="relative h-screen flex justify-center items-center flex-col">
        {/* <LoadingScreen /> */}
        <h1 className="mt-4 text-center text-xl font-bold">logging in</h1>
      </div>
    );
  }

  return children;
}
