export default function Section({ children, classes, withContainer }) {
  return (
    <div className={`p-4 ${classes}`}>
      <div
        className={
          withContainer
            ? "md:max-w-3xl lg:max-w-4xl xl:max-w-6xl mx-auto"
            : "max-w-full"
        }
      >
        {children}
      </div>
    </div>
  );
}
