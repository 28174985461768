import { useEffect } from "react";
import Section from "./styles/section";
import Logo from "./icons/logo";
import AccountIcon from "./icons/account";
import Link from "next/link";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import NextImage from "next/image";

export default function Header() {
  const router = useRouter();
  const { data: session } = useSession();

  const isCurrentRoute = (pathname, isNested) => {
    let result;
    if (isNested) {
      result = router.pathname.indexOf(pathname) !== -1;
    } else {
      result = router.pathname === pathname;
    }

    return result;
  };

  // Redirects to login screen if user not logged in
  // useEffect(() => {
  //   if (!session) {
  //     if (router.pathname !== "/login") {
  //       router.push("/login");
  //     }
  //   }
  // }, [session, router]);

  return (
    <Section withContainer classes="py-4 bg-white text-primary">
      <header className="flex flex-col items-center sm:flex-row">
        <Link href="/" className="mb-4 sm:mb-0 sm:mr-6">
          <Logo />
          <span className="sr-only">Gatelinks</span>
        </Link>
        {session ? (
          <nav className="grow text-primary">
            <ul className="flex items-center w-full font-medium text-sm">
              <li
                className={`${
                  isCurrentRoute("/")
                    ? "text-white bg-primary100"
                    : "text-primary"
                } py-2 px-3 rounded mr-1`}
              >
                <Link href="/">Dashboard</Link>
              </li>
              <li
                className={`${
                  isCurrentRoute("/my-lists")
                    ? "text-white bg-primary100"
                    : "text-primary"
                } py-2 px-3 rounded`}
              >
                <Link href="/my-lists">My Lists</Link>
              </li>
              <li className={`grow flex justify-end`}>
                <Link
                  href="/account"
                  className={`${
                    isCurrentRoute("/account")
                      ? "text-white bg-primary100"
                      : "text-primary sm:bg-primary100"
                  } p-2 rounded-full overflow-hidden w-10 h-10 relative`}
                >
                  {session?.user?.image ? (
                    <>
                      <NextImage
                        src={session?.user?.image}
                        alt=""
                        className="absolute top-0 left-0 w-full h-full object-cover"
                        width="40"
                        height="40"
                      />
                      <div className="bg-primary100 opacity-30 w-full h-full absolute top-0 left-0 hover:opacity-0"></div>
                    </>
                  ) : (
                    <AccountIcon />
                  )}

                  <span className="sr-only">Account</span>
                </Link>
              </li>
            </ul>
          </nav>
        ) : null}
      </header>
    </Section>
  );
}
